/* .white-box-1:nth-child(3) .container { */
.white-box-1:nth-child(1) {
    padding-bottom: 5em;
}

.thk-you {
    color: #ce3679;
    font-size: 60px;
    font-weight: bold;
}
.container.ty {
    background-color: #fff;
    padding-right: 0;
    padding-left: 0;
}

.head-text.m-50 {
    border-top: 10px solid #ce3679;
    padding-top: 1em;
}

.icon-page {
    padding-bottom: 2em;
}

.icon-page img:hover {
    cursor: pointer;
}
