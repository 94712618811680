.contact-info {
    /* font-size: 13px; */
    /* color: #676767; */
    color: #fff;

    margin: 1em 0 1em 0;
    background-color: #ce3679;
    padding: 1em 1em 1em 1em;
    display: flex;
}
.contact-info label {
    /* padding: 0.2em 0.2em 0.2em 1em; */
    font-weight: bold;
}
.contact-info .column-info {
    flex-grow: 1;
    width: 33%;
}

.contact-info input {
    border: none;
    background-color: #ce3679;
    color: #fff;
    width: 100%;
}

.contact-info input:focus {
    outline: none;
}

.quote-form {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    text-align: left;
    padding-left: 1em;
    font-weight: 500;
    margin: 2em 0 2em 0;
    /* border-top: 4px solid #E02676; */
}
.quote-form label {
    margin-top: 1em;
}

.quote-form input {
    width: 27em;
    border: 2px solid #e5e5e6;
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
    padding: 15px;
}
.quote-form .err-form {
    color: red;
    font-size: 12px;
}

.company-info {
    list-style-type: none;
}

@media (max-width: 767px) {
    .contact-info {
        flex-direction: column;
    }

    .contact-info .column-info {
        margin-bottom: 1em;
    }

    .contact-info .column-info {
        flex-grow: 1;
        width: 100%;
    }
}
