.btn-2 button a {
    color: #6c757d !important;
    text-decoration: none !important;
}

.get-quote {
    color: #e83e8c !important;
    text-align: justify !important;
    font-size: 4.3vw !important;
}
.get-quote:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.num-of .user {
    visibility: hidden !important;
}

.hidden {
    visibility: hidden !important;
}
.fr {
    width: 275px;
}
.talk-sale .btn-1 button,
.talk-sale .btn-2 button {
    border-radius: 20px;
}

.main-price.mp-1,
.main-price.mp-1 .prc-text h2 {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.main-price.mp-1 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
