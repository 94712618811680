.arrow-box span {
    cursor: pointer;
}

.input-feedback {
    color: #ff2f40;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
}

.card-num {
    display: flex;
    flex-direction: column;
    font-weight: 600;
}
.card-num label {
    text-align: left;
}

.card-num .cvv {
    margin-left: -15px;
}

.cvv-txt {
    padding-left: 0.5em;
}

.pay-method {
    margin-left: 2em;
}
.pay-card {
    margin-left: 2em;
}
/* .payment.cvv {
   padding-left: 0 !important;
} */
.payment input {
    border: 2px solid #e5e5e6;
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
    padding: 15px;
}

.payment .req-star {
    color: #ce3679;
}

.city-name {
    text-align: left;
}
.city-name label {
    text-align: left !important;
}
.col label {
    text-align: left;
}

.pay-form .row {
    margin-right: 0;
    margin-left: 1em;
}

.pl-0 {
    padding-left: 15px !important;
}

.pr-0,
.px-0 {
    padding-right: 15px;
}

.price .onb-total {
    font-size: 25px;
}
.price .onb-total {
    font-size: 20px;
}

/*style from quoteBillingInfo - error handling*/
.err-msg {
    color: #fff;
    border-color: #f75676;
    background-color: #ff6d79;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.2em;
    padding-right: 0.2em;

    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1em;
    border-radius: 7px;
}

.err-msg i {
    font-size: 40px;
    margin: 0.2em;
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.5em;
}

.err-msg span {
    text-align: center;
    line-height: 4em;
    font-size: 12px;
}
.text-input.error {
    border: 1px solid red;
}

button:disabled {
    opacity: 0.5;
}

.sel-country {
    cursor: pointer;
    height: 48px;
    border-radius: 5px;
    padding: 2px 15px;
    background-color: #f3f3f4;
    font-weight: 600;
    color: #48484a;
    appearance: none;
}

.req-star {
    color: #ce3679;
}
select.sel-province {
    width: 100% !important;
    /* for Firefox */
    -moz-appearance: none !important;
    /* for Chrome */
    -webkit-appearance: none !important;

    padding: 2px 15px;
    background-color: #fff;
    font-weight: 600;
    /* color: #fff; */
    appearance: none;
    border: 2px solid #e5e5e6;
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
}

#test-taxid {
    position: relative;
    top: -34px;
    overflow: hidden;
    left: 15px;
    width: 77%;
    pointer-events: none;
    border: none;
    color: #676767;
    font-weight: bold;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

#test-taxid::-ms-expand {
    display: none;
}
.trial-msg {
    font-size: 12px;
    color: gray;
}

.total #taxName {
    color: gray;
    font-size: 16px;
}

.tax-code {
    font-size: 11px;
    padding-left: 0.5em;
}

/* For IE10 */
select.sel-province::-ms-expand {
    display: none !important;
}

.country-2 input {
    border: 2px solid #e5e5e6;
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
    padding: 15px;
}

.city-name {
    padding-left: 15px !important;
}

/* change the default border here, otherwise anywhere else it will overwrite the error styling of the fields*/
.bank-account input {
    border: 2px solid #e5e5e6;
}

.bank-account input[type='text'] {
    /* border: 2px solid #e5e5e6; */
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
    padding: 15px;
}

.payment-type {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 10px;
}
.payment-type label {
    border: 2px solid #e5e5e6;
    padding: 10px 0 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: -10px;
}
.payment-type label.payment-picked {
    border: 2px solid #ce3679;
    color: #ce3679;
}

.payment-type input {
    margin-right: 0.5em;
}
.payment-type input[type='radio'] {
    /* display: none; */

    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 1px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #e5e5e6;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.payment-type input[type='radio']:checked {
    background-color: #e0267a;
    border-color: #e0267a;
}

.account-type input[type='radio']:checked {
    background-color: #e0267a;
    border-color: #e0267a;
}

.account-type input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    padding: 1px;
    background-clip: content-box;
    border: 2px solid #e5e5e6;
    border-radius: 50%;
    cursor: pointer;
}

.bank-account .col {
    text-align: left;
    margin-bottom: 1em;
}

.bank-account .account-type {
    display: flex;
    flex-direction: column;
}

.bank-account .account-type input {
    margin-right: 0.5em;
    /* padding-left: 1em; */
}

.row-billing-center {
    align-items: center;
    justify-content: center;
}
.row-billing-hide {
    display: none;
}
.row-no-bottom {
    border-bottom: none;
}
.row-no-top {
    border-top: none;
}

.pay-card {
    text-align: left;
}

.edit-icon {
    transform: scale(2) translateX(2px);
}

.Country.country-2 {
    margin-left: 0;
    margin-right: 1px;
    margin-top: 1em;
}

.Country.country-2 label {
    display: none;
}
/********/

@media (min-width: 1200px) {
    .Country .select-box {
        transform: translateX(-1.5em) !important;
    }
    .Country .down-arrow {
        right: 2em !important;
    }
    .Country .select-box .input-feedback {
        transform: translateX(2em) !important;
    }
    .edit-icon {
        transform: scale(2) translateX(2px);
    }
}
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 49%;
        flex: 0 0 49%;
        max-width: 50%;
    }

    .col-md-12.col-lg-6.pr-0.my-pl {
        width: 98%;
        /* margin-left: 1em; */
        margin-left: 15px;
    }

    .pr-0,
    .px-0 {
        padding-right: 0 !important;
    }

    .col-md-12 {
        max-width: 98%;
    }

    .col-md-12.col-lg-6.pr-0.my-pl {
        padding-left: 0 !important;
    }

    .payment.cvv {
        padding-left: 15px !important;
    }
    .arrow-box {
        transform: translateX(0);
    }
    .arrow-box.onb-hidden {
        transform: translateX(0);
    }

    .Country .select-box {
        transform: translateX(-1.5em);
    }
    .Country .down-arrow {
        right: 33px;
    }

    .edit-icon {
        transform: scale(2) translateX(2px);
    }
}
@media (max-width: 992px) {
    /* .arrow-box{
         transform: translateX(76%);
   } */
    .arrow-box.onb-hidden {
        transform: translateX(76%);
    }
    .edit-icon {
        transform: scale(2) translateX(-2px);
    }
    .Country.country-2 {
        margin-top: 2em;
        margin-bottom: 1em;
    }
}
@media (max-width: 768px) {
    .arrow-box.onb-hidden {
        transform: translateX(52%);
    }
    .arrow-box {
        transform: translateX(1);
    }
    .edit-icon {
        transform: scale(2) translateX(-5px);
    }
    .Country.country-2 {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

@media (max-width: 500px) {
    .select-box {
        width: 9em;
    }

    .add-on-con {
        font-size: 17px;
    }
}

@media (max-width: 333px) {
    .select-box {
        width: 9em;
    }
}

.row {
    margin-right: 0;
}

.col-md-12.col-lg-6.pr-0.my-pl {
    padding-left: 0 !important;
}
/* .col-lg-6{
   flex: 0 0 49.3%;
} */
/* .add-on-con:nth-of-type(1){
   border-top: 2px solid #e0267a;
} */
