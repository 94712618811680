.check-out {
    position: static;
    margin-top: -2em;
}

.white-box-1 {
    padding: 0;
}
.check-form {
    margin-left: 1em;
    margin-right: 1em;
}
.billing-box {
    margin-top: 0;
}

/*third page*/
.add-on-con {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 17px;
    color: #676767;
    border-top: 2px solid #e5e5e6;
    padding: 1em;
}
.add-on-con.header {
    border-top: none;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
}

/* .add-on-el{
    display: flex;
    flex-direction: row;
    align-items: center;
} */
.add-on-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    /* margin-left: 7em; */
}

.add-on-name {
    margin-right: 0.5em;
}

.add-on-name > input {
    width: 25px;
    height: 25px;
    margin-right: 0.5em;
    cursor: pointer;
}
.add-on-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 6em;
}
.add-on-side.extra > .add-on-qty,
.add-on-side.extra > .add-on-qty > input,
.add-on-side.extra > .add-on-prc {
    color: #e5e5e6;
}
.add-on-side.extra {
    color: #d8d8d8;
}
.add-on-side.extra > .add-on-qty > input {
    pointer-events: none;
}

.add-on-qty {
    text-align: right;
}
.add-on-prc {
    width: 8em;
}
/* .add-on-qty, .add-on-qty.disabled{
    margin-right: 3em;
    width: 10em !important;

} */

.add-on-qty > input {
    width: 40px;
    border: 2px solid #e5e5e6;
    box-shadow: none;
    height: 40px;
    border-radius: 5px;

    margin: 0 0.5em 0 0.5em;

    color: #676767;
    font-size: 22px;
    text-align: center;
}

.add-on-qty.disabled > input {
    pointer-events: none;
    border-color: #fff;
}
.add-on-qty > span {
    font-size: 15px;
    font-weight: 500;
}
.add-on-prc {
    text-align: right;
}
.add-on-prc > span {
    font-size: 12px;
    font-weight: 500;
}
.btn-total {
    border-top: 4px solid #ce3679;
}
.add-on-name {
    flex: 0 0 43em;
}
.add-on-prc {
    width: 8em;
}
.dummy {
    visibility: hidden;
}

.price .ord-subtotal {
    font-size: 20px;
}

.price span:nth-child(1) {
    font-size: 20px;
}
.retrieve-error {
    padding: 10em 0 10em 0;
}

.add-on-name {
    flex: 0 0 35em;
}
.prc-view {
    flex-basis: 10%;
}
.qty-view {
    flex-basis: 7%;
}
.name-view {
    flex-basis: 64%;
}
.unit-prc-view {
    margin-left: 3em;
    width: 6em;
}
.add-on-test {
    width: 12em;
}

.unit-prc-view .prc-code {
    font-size: 12px;
}

.qty-view-bill input {
    background-color: #fff;
}

.add-on-con.view.header .name-view-bill {
    font-size: 17px;
}

@media only screen and (min-width: 1200px) {
    .add-on-qty {
        width: 10em;
    }

    .add-on-prc.noqty1 {
        width: 8em;
    }

    .add-on-prc.noqty2 {
        width: 10.5em;
    }
    .add-on-name {
        flex: -0 0 35em;
    }
    .add-on-test {
        width: 13em;
    }
    .add-on-prc.head {
        width: 5.5em;
    }
    .add-on-prc {
        width: 7.5em;
    }
    .unit-prc-view {
        margin-left: 3.5em;
        width: 6em;
    }

    .name-view-bill {
        flex: -0 0 13em;
        text-align: left;
    }
    .qty-view-bill {
        width: 5em;
    }
    .unit-prc-view-bill {
        margin-left: 1.5em;
        width: 8em;
    }
    .prc-view-bill {
        width: 6em;
    }
    .prc-view-bill .prc-code,
    .unit-prc-view-bill .prc-code {
        font-size: 11px;
    }
    .qty-view-bill input {
        width: 100%;
        text-align: center;
        border: none;
    }
}

@media only screen and (max-width: 1200px) {
    .add-on-name {
        flex: 0 0 32em;
    }
    .add-on-qty,
    .add-on-qty.disabled {
        width: 4em;
    }
    .add-on-prc {
        width: 6em;
    }
    .add-on-prc.head {
        width: 5em;
    }
    .add-on-prc.noqty1 {
        width: 8em;
    }
    .add-on-prc.noqty2 {
        width: 9em;
    }
    /* .add-on-qty, .add-on-qty.disabled{
        
        width: 11em;
    
    }
    .add-on-prc {
        width: 10em;
    } */

    .name-view-bill {
        flex: -0 0 13em;
        text-align: left;
    }
    .qty-view-bill {
        width: 5em;
    }
    .unit-prc-view-bill {
        margin-left: 0.5em;
        width: 6em;
    }
    .prc-view-bill {
        width: 6em;
    }
    .prc-view-bill .prc-code,
    .unit-prc-view-bill .prc-code {
        font-size: 11px;
    }
    .qty-view-bill input {
        width: 100%;
        text-align: center;
        border: none;
    }
}
@media only screen and (max-width: 991px) {
    /* .add-on-name {
        flex: 0 0 17em;
        
    }
    .add-on-prc {
        width: 7em;
    } */
    .add-on-prc.head {
        width: 4em;
    }
    .add-on-test {
        width: 11.5em;
    }
    .add-on-prc {
        width: 4.5em;
    }
    .add-on-name {
        flex: 0 0 12em;
    }
    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    /* .add-on-qty, .add-on-qty.disabled {
        margin-right: 0 !important; 
        width: 15em !important;
    } */

    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 12em !important;
    }
    .add-on-prc.noqty2 {
        width: 7.5em;
    }

    .name-view-bill {
        flex: -0 0 23em;
        text-align: left;
    }
}

@media only screen and (max-width: 767px) {
    .add-on-name {
        flex: 0 0 7em;
    }
    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 10em !important;
    }
    .add-on-prc {
        width: 4em;
    }

    .add-on-test {
        width: 9em;
    }
    .add-on-prc.head {
        width: 2.5em;
    }
    .unit-prc-view .prc-code {
        font-size: 14px;
    }
    .unit-prc-view {
        margin-left: 2em;
        width: 4em;
    }
    .add-on-prc.noqty1 {
        width: 6em;
    }
    .add-on-prc.noqty2 {
        width: 6em;
    }

    .name-view-bill {
        flex: -0 0 13em;
        text-align: left;
    }
}

@media only screen and (max-width: 550px) {
    .add-on-con.view .disc {
        top: 55px;
    }

    .add-on-prc {
        width: 6.5em;
    }

    .add-on-name {
        flex: 0 0 19em;
    }

    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 4em !important;
    }

    .add-on-con {
        font-size: 14px;
    }

    .add-on-con.header .add-on-name {
        font-size: 14px;
    }

    .add-on-side {
        font-size: 14px;
    }

    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-qty > input {
        width: 30px;
        height: 30px;
    }

    .add-on-prc {
        width: 4.5em;
        text-align: center;
    }

    .add-on-con.view.header .name-view-bill {
        font-size: 14px !important;
    }

    .add-on-prc.noqty1 {
        width: 7em;
    }

    .name-view-bill {
        flex: -0 0 20em;
        text-align: left;
    }
}
@media (max-width: 500px) {
    .add-on-prc.head {
        width: 5.5em;
    }
    .add-on-prc.noqty2 {
        width: 8.5em;
    }

    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-con.header .add-on-name {
        flex: 0 0 13em;
    }

    .add-on-con {
        font-size: 14px !important;
    }

    .add-on-con.header .add-on-name {
        font-size: 14px;
    }

    .add-on-side {
        font-size: 14px;
    }

    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-qty > input {
        width: 30px;
        height: 30px;
    }

    .add-on-prc {
        width: 7.5em;
        text-align: center;
    }
    .add-on-name {
        flex: 0 0 13em;
    }

    .add-on-prc.noqty1 {
        width: 8em;
    }

    .add-on-con.header .add-on-name.quote {
        flex: 0 0 16em;
    }

    .add-on-prc.head.quote {
        width: 3.5em;
    }

    .prc-view .prc-value {
        font-size: 14px;
    }
    /* .prc-view .prc-value {
        font-size: 14px;
    }
    .add-on-con.header .add-on-name {
        flex: 0 0 16em;
    }
    .add-on-test {
        width: 9em;
    }
    .add-on-prc.head {
        width: 3.5em;
    } */
}
@media (max-width: 450px) {
    .add-on-con.header .add-on-qty {
        width: 4em !important;
    }

    .add-on-con.header .add-on-prc {
        font-size: 14px;
    }

    .add-on-con.header .add-on-name {
        flex: 0 0 13em;
    }

    .add-on-con {
        font-size: 14px !important;
    }

    .add-on-con.header .add-on-name {
        font-size: 14px;
    }

    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-qty > input {
        width: 30px;
        height: 30px;
    }

    .add-on-prc {
        width: 7.5em;
        text-align: center;
    }
    .add-on-prc.noqty1 {
        width: 8em;
    }

    /* .add-on-con.header{
        display: flex;
        flex-direction: column;
    }
    .add-on-name {
        flex-basis: 50%;
    }
    .add-on-side{
        font-size: 14px;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    } */
}
@media only screen and (max-width: 415px) {
    .add-on-name {
        flex: 0 0 7em;
    }

    .add-on-test {
        margin-left: 1em;
        width: 4.5em;
    }

    .add-on-prc {
        width: 5.5em;
    }
    .add-on-con.view .disc {
        top: 55px;
    }
    .add-on-con {
        font-size: 14px;
    }
    .add-on-test {
        margin-left: 1em;
    }
    .unit-prc-view {
        font-size: 17px;
    }
    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-con.header .add-on-qty {
        width: 6em !important;
    }

    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 8em !important;
    }
    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 13em !important;
    }

    .add-on-con {
        font-size: 14px;
    }

    .add-on-con.header .add-on-name {
        font-size: 14px;
    }

    .add-on-con.header .name-view-bill {
        flex: -0 0 11em;
    }

    /*     
    .unit-prc-view .prc-code {
        font-size: 12px;
    }

    .add-on-qty > input {
        width: 30px;
        height: 30px;
    }

    .add-on-prc {
        width: 7.5em;
        text-align: center;
    }
    .add-on-prc.noqty1 {
        width: 8em;
    } */
}

@media only screen and (max-width: 360px) {
    .add-on-prc {
        width: 6em;
    }

    .add-on-con.header .add-on-qty {
        width: 4em !important;
    }
    .add-on-qty,
    .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 9em !important;
    }
}
@media only screen and (max-width: 333px) {
    .add-on-name {
        flex: 0 0 8em;
    }
}

@media (max-width: 333px) {
    .add-on-name {
        flex: 0 0 8em;
    }
    .down-arrow {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #a09fa2;
        top: 15px;
        right: 80px;
        position: absolute;
        pointer-events: none;
        margin-top: 0.25em;
    }
    select.sel-province {
        width: 61% !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        padding: 2px 15px;
        background-color: #fff;
        font-weight: 600;
        color: #fff;
        appearance: none;
        border: 2px solid #e5e5e6;
        box-shadow: none;
        height: 48px;
        border-radius: 5px;
    }

    #test-taxid {
        position: relative;
        top: -34px;
        overflow: hidden;
        left: 15px;
        width: 40%;
        pointer-events: none;
        border: none;
        color: #676767;
        font-weight: bold;
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
    }

    .city-name label {
        font-size: 15px;
    }
}

@media (max-width: 333px) {
    .add-on-con.header .add-on-name {
        font-size: 17px;
    }
    .add-on-con.header .add-on-qty {
        font-size: 14px;
        width: 4em !important;
    }
    .add-on-con.header .add-on-prc {
        font-size: 14px;
    }
}
@media only screen and (max-width: 320px) {
    .contact-info .column-info {
        font-size: 13px;
    }
    .add-on-con {
        font-size: 13px;
    }
    /* .add-on-qty, .add-on-qty.disabled {
        margin-right: 0 !important;
        width: 8em !important;
    } */
}
