.white-box-1 {
    max-width: 1155px !important;
}

.pay-con button {
    border-radius: 7px;
}

.err-msg {
    color: #fff;
    border-color: #f75676;
    background-color: #ff6d79;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.2em;
    padding-right: 0.2em;

    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1em;
    border-radius: 7px;
}

.err-msg i {
    font-size: 40px;
    margin: 0.2em;
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.5em;
}

.err-msg span {
    text-align: center;
    line-height: 4em;
    font-size: 12px;
}

.legacy {
    display: none;
}
.legacyFlex {
    flex: 1;
}

.repos {
    margin-left: 1.5em;
}

/* change the default border here, otherwise anywhere else it will overwrite the error styling of the fields*/
.bank-account input {
    border: 2px solid #e5e5e6;
}

.bank-account input[type='text'] {
    /* border: 2px solid #e5e5e6; */
    box-shadow: none;
    height: 48px;
    border-radius: 5px;
    padding: 15px;
}

.payment-type {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 10px;
}
.payment-type label {
    border: 2px solid #e5e5e6;
    padding: 10px 0 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: -10px;
}
.payment-type label.payment-picked {
    border: 2px solid #ce3679;
    color: #ce3679;
}

.payment-type input {
    margin-right: 0.5em;
}
.payment-type input[type='radio'] {
    /* display: none; */

    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 1px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #e5e5e6;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.payment-type input[type='radio']:checked {
    background-color: #e0267a;
    border-color: #e0267a;
}

.account-type input[type='radio']:checked {
    background-color: #e0267a;
    border-color: #e0267a;
}

.account-type input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    padding: 1px;
    background-clip: content-box;
    border: 2px solid #e5e5e6;
    border-radius: 50%;
    cursor: pointer;
}

.bank-account .col {
    text-align: left;
    margin-bottom: 1em;
}

.bank-account .account-type {
    display: flex;
    flex-direction: column;
}

.bank-account .account-type input {
    margin-right: 0.5em;
    /* padding-left: 1em; */
}

.row-billing-center {
    align-items: center;
    justify-content: center;
}
.row-billing-hide {
    display: none;
}
.row-no-bottom {
    border-bottom: none;
}
.row-no-top {
    border-top: none;
}

.pay-detail {
    color: #676767;
}

.arrow-box .right-a {
    margin-bottom: 0.55em;
}

.arrow-box .marged-a {
    margin-right: -0.5em;
}

.arrow-box .marged-b {
    margin-right: 1.5em;
}

.billing-box {
    margin-top: 1em;
}

.icon-bill {
    text-align: right;
    transform: translateX(-3em);
    z-index: 1;
    margin-right: 1em;
}

.arrow-center {
    text-align: center;
}

.arrow-box {
    transform: translateX(0);
}

.country-2 {
    margin-left: 1em;
}

.pay-card {
    text-align: left;
}

@media only screen and (max-width: 1200px) {
    .name-view-bill.quote {
        flex: -0 0 10em;
        text-align: left;
    }
}

@media only screen and (max-width: 990px) {
    .add-on-con.view {
        border-left: 1px solid #fff;
    }
    .arrow-box.onb-hidden {
        transform: translateX(72%);
    }
    .arrow-box {
        transform: translateX(49%);
    }

    .add-on-con.view.header {
        border-left: 1px solid #fff;
    }

    .name-view-bill.quote {
        flex: -0 0 20em;
        text-align: left;
    }
}

/* styling for when 'other' is selected on the country dropdown (768px and lower), 
we have to give it some top space 
*/
@media only screen and (max-width: 768px) {
    .country-2 {
        margin-top: -1em;
        margin-left: 0;
    }
    .arrow-box {
        transform: translateX(0);
    }
    .arrow-box.onb-hidden {
        transform: translateX(46%);
    }

    .select-box {
        width: 12em;
    }

    .name-view-bill.quote {
        flex: -0 0 12em;
        text-align: left;
    }
}

@media only screen and (max-width: 500px) {
    .name-view-bill.quote {
        flex: -0 0 15em;
        text-align: left;
    }
}
@media only screen and (max-width: 450px) {
    .select-box {
        width: 11em;
    }
}

@media only screen and (max-width: 420px) {
    .select-box {
        width: 10em;
    }
}

/* styling for when 'other' is selected on the country dropdown (990px and beyond), 
we have to give it some left space 
*/
@media only screen and (min-width: 990px) {
    /* .country-2{
        margin-left: 1em;
    }

    .arrow-box{
        transform: translateX(49%);
    } */

    .Country .select-box {
        transform: translateX(0em);
    }
    .Country .down-arrow {
        /* right: 33px; */
        right: 7px;
    }
}
