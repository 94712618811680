.add-on-con.view {
    position: relative;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    /* font-weight: 600; */
    /* color: #676767; */
    /* color: #212529; */
    /* border-left: 1px solid #ccc; */
    /* border-bottom: 1px solid #ccc; */

    /* padding: 0.2em 0.2em 0.2em 1em; */
    padding: 0.5em 0.2em 1.2em 1em;
    border-top: 2px solid #e5e5e6;
    color: #676767;
}

.add-on-con.view .disc {
    position: absolute;
    font-size: 11px;
    right: 4px;
    top: 58px;
    color: lightslategray;
}

.col-md-12.col-lg-6.pr-0.my-pl {
    border-top: 2px solid #e0267a;
    padding-left: 0 !important;
}

/* .add-on-con.view div{
    flex-basis: 33%;
    flex-grow: 0;
} */

.name-view {
    flex-basis: 60%;
    flex-grow: 0;
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-align: left;
    /* color: #212529; */
}
.prc-view {
    flex-basis: 20%;
    flex-grow: 0;
    display: flex;
    /* font-size: 1rem; */
    /* font-weight: bold;  */
    text-align: right;
}

.prc-view .prc-value {
    /* font-size: 17px; */
    font-size: 16px;
    /* font-weight: bold; */
}

.add-on-con.view.header .prc-view {
    font-size: 17px;
}

.qty-view {
    /* flex-basis: 40%; */
    flex-basis: 15%;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1em;
}
.qty-view > span {
    font-size: 15px;
    font-weight: 500;
}
.qty-view > input {
    width: 2em;
    height: 2em;
    border: 2px solid #e5e5e6;
    box-shadow: none;
    border-radius: 5px;
    margin: 0 0.5em 0 0.5em;
    color: #676767;
    font-size: 22px;
    text-align: center;
    pointer-events: none;
    border-color: #fff;
    background-color: #fff;
}
.prc-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.prc-view > span {
    font-size: 12px;
    font-weight: 500;
}

.unit-prc-view {
    margin-right: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
}
